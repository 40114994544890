import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import breaks from 'remark-breaks'

import { Layout } from '../components/layout'
import { SEO } from '../components/seo'
import { Intro } from '../components/intro'
import { Container } from '../components/container'
import { Text } from '../components/text'
import { Flex } from '../components/flex'

export default function ImpressumPage({ data }) {
  const content = data.strapiPage.text

  return (
    <Layout>
      <SEO title="Impressum" />
      <Container>
        <Flex>
          <Flex.Item width={{ desktop: 8 }} offset={{ desktop: 2 }}>
            <Intro>Impressum</Intro>
            <Text>
              <ReactMarkdown source={content} plugins={[breaks]} />
            </Text>
          </Flex.Item>
        </Flex>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiPage(strapiId: { eq: 1 }) {
      text
      title
      id
    }
  }
`
